import { handleExperimentsResponse } from '../../common/store/experiments/experiments-actions';
import { handleTranslationsResponse } from '../../common/store/translations/translations-actions';
import { getQueryLocale } from '../../common/selectors/locale-selectors';
import { handlePublicationsCountResponse } from '../../common/store/blog-stats/blog-stats-actions';
import { handleCategoriesResponse } from '../../common/store/categories/fetch-categories';

export const fetchCategoryWidgetInitialData =
  () =>
  async (dispatch, getState, { aggregatorRequest }) => {
    const langQuery = getQueryLocale(getState());
    const language = langQuery ? `language=${langQuery}` : '';
    const { experiments, translations, categories, publicationsCount } = await aggregatorRequest(
      `/v1/category-menu-widget/render-model?${language}`,
      { throwOnInvalidJson: true },
    );

    await Promise.all([
      dispatch(handleExperimentsResponse(experiments)),
      dispatch(handleTranslationsResponse(translations)),
      dispatch(handleCategoriesResponse(categories)),
      dispatch(handlePublicationsCountResponse(publicationsCount || {})),
    ]);
  };
