import { get } from 'lodash';
import { ROUTE_404 } from '../../constants/routes';
import { SECTION_CATEGORY, isExperimentEnabled, fetchTag } from '@wix/communities-blog-client-common';
import { EXPERIMENT_TAGS_PAGE_SEO } from '@wix/communities-blog-experiments';
import { getIsValidPage, getShowPagination } from '../../../common/selectors/pagination-selectors';
import { isExcludePostContentSupported } from '../../../common/selectors/layout-selectors';
import { fetchTagPosts } from '../../../common/actions/fetch-tag-posts';
import { isSSR, isSite } from '../../../common/store/basic-params/basic-params-selectors';
import { pageOpened } from '../../../common/actions/page-opened';
import { TAGS_PAGE } from '../../../common/services/detect-route';
import { generateTagSEOTags } from '../../../common/services/generate-seo-tags/generate-tag-seo-tags';
import { getTagUrl } from '../../../common/services/get-tag-url';
import { translate } from '../../../common/services/controller-translate';
import { setMetaTagRobotsNoIndex } from '../../../common/services/set-metatag-robots-noindex';
import { getPostPageSectionUrl, getSectionUrl } from '../../../common/store/topology/topology-selectors';
import { fetchProfileUrls } from '../../../common/store/profile-urls/profile-urls-actions';

export const createTagPageRouter =
  (store, wixCodeApi) =>
  ({ params }, redirect) => {
    const state = store.getState();

    const isTagsPageSEOEnabled = isExperimentEnabled(state, EXPERIMENT_TAGS_PAGE_SEO);
    if (!isTagsPageSEOEnabled) {
      setMetaTagRobotsNoIndex(wixCodeApi);
    }

    let tag;

    const page = parseInt(get(params, 'page', '1'), 10);
    const tagSlug = get(params, 'tagSlug');

    return store
      .dispatch(fetchTag(tagSlug))
      .then((_tag) => {
        tag = _tag;
        return store.dispatch(
          fetchTagPosts({
            tagId: _tag.id,
            page,
            excludeContent: isExcludePostContentSupported(store.getState(), SECTION_CATEGORY),
          }),
        );
      })
      .then(({ body: posts }) => {
        const showPagination = getShowPagination(store.getState(), SECTION_CATEGORY);
        const isValidPage = getIsValidPage(store.getState(), SECTION_CATEGORY);
        if (showPagination && !isValidPage) {
          return redirect(ROUTE_404);
        }
        store.dispatch(fetchProfileUrls());

        if (isSite(state)) {
          const sectionUrl = getSectionUrl(state);
          if (isTagsPageSEOEnabled) {
            const tagSEOTags = generateTagSEOTags({
              sectionUrl,
              postPageSectionUrl: getPostPageSectionUrl(state),
              showPagination,
              tag,
              state,
              page,
              posts,
              t: translate,
            });
            wixCodeApi.seo.renderSEOTags(tagSEOTags);
          } else {
            const tagUrl = getTagUrl(sectionUrl, tag.slug);
            wixCodeApi.seo.setTitle(tag.label);
            wixCodeApi.seo.setLinks([{ href: tagUrl, rel: 'canonical' }]);
          }
        }

        !isSSR(store.getState()) && store.dispatch(pageOpened({ page: TAGS_PAGE }));
      })
      .catch((error) => {
        if (error.status === 404) {
          return redirect(ROUTE_404);
        }
        throw error;
      });
  };
